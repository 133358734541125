import React from "react"

import SEO from "../components/seo"
import "../components/layout.scss" 
import { useIntl } from "gatsby-plugin-intl"

const NotFoundPage = () => {
  const intl = useIntl()
  return(
  <div>
    <SEO 
    
      />
    <header className="masthead">
      <div className="container h-100">
        <div className="row h-100 align-items-center justify-content-center text-center">
          <div className="col-lg-10 align-self-end">
            <h1 className="text-white font-weight-bold">{intl.formatMessage({ id: "notfound.header" })}</h1>
            <hr className="divider my-4" />
          </div>
          <div className="col-lg-8 align-self-baseline">
            <h3 className="text-white font-weight-bold mb-5">
              {intl.formatMessage({ id: "notfound.description" })}
            </h3>
            <a className="js-scroll-trigger" href="/#page-top">
              <div className="btn btn-primary btn-xl js-scroll-trigger">
               {intl.formatMessage({ id: "go_back" })}   
              </div>
            </a>
          </div>
        </div>
      </div>
    </header>
  </div>)

  
}

export default NotFoundPage
